<template>
    <div class="center">
        <CCard class="col-md-4 p-4 justify-content-center">
            <center>
         <h4 style="color:red;">Your Account is not activated</h4>
         <div v-if="show_input">
                <label class="mt-3">Please enter Verification Code below and click on Verify Now to get verified.</label>
                If not received click to <span class="link" @click="VerifyAccount()">Resend code.</span>
            <CInput
                class="col-md-6"
                size="sm"
                id="input-1"
                v-model="input.otp"
                type="text"
                required
                placeholder="Enter verification code here"
            ></CInput>
            <CButton color="success" type="submit" v-on:click="SubmitOtp">
                    Verify Now</CButton
                >
                <br>
                
         </div>
         <div v-else>
         <label>We will send verification code to your mail. </label>
         <label><span class="link" @click="VerifyAccount()">&nbsp;Click here</span> to send verification code.</label>
        </div>
    </center>
      </CCard>
    </div>
</template>



<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import LoginService from './../../services/loginControlService'
export default{
    name:'VerifyAccount',
    data(){
        return{
            show_input:false,
            input:{}
        }
    },
    methods:{
       async VerifyAccount(){
            let response = await LoginService.getOtp()
            if(response.result){
                Swal.fire({title: 'Success',text: "OTP sent to your registered Email",icon: 'success',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 3000})
                this.show_input = true
            }
        },
        async SubmitOtp(){
            let response = await LoginService.verifyOtp(this.input)
            if(response.result){
                window.localStorage.setItem('status', 'true')
                Swal.fire({title: 'Success',text: response.message,icon: 'success',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 3000})
                this.logout()
            }
            else{
                Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 3000})
                this.input.otp = ''
            }
        },
       async logout() {
            let response = await LoginService.logout()
              window.localStorage.setItem("user", null);
              window.localStorage.setItem("status", null);
              window.localStorage.setItem("email", null);
              window.localStorage.setItem("iwitness", 'false');
              this.$router.push("/pages/login");
          },
    }
}
</script>



<style scoped>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}
</style>